import React from "react";
import { graphql } from "gatsby";
import useScript from "../hooks/useScript";

import Layout from "../components/layout";
import { UxResearcherJsonLd } from "../components/jsonld/UxResearcherJsonLd";

// import IconCall from "../images/icons/theme/communication/call-1.svg";
// import IconMail from "../images/icons/theme/communication/send.svg";
//
// import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
// import LogoRedbull from "../images/logos/brand/grey-hubspot.svg";
// import LogoHubspot from "../images/logos/brand/grey-red-bull.svg";
// import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";
// import IconCheck from "../images/icons/interface/check.svg";

const UXresearcherPage = ({ data, location: { pathname } }) => (
  <Layout
    headerBgClass="bg-primary-alt"
    hideFooter
    footerBgClass="bg-white"
    meta={{ seo: data.datoCmsPage.seo, path: pathname }}
  >
    <UxResearcherJsonLd />

    <div className="contact-us-page">
      <div className="contact-us-page__bg bg-primary-alt">
        {/*<div className="divider">
          <Divider3 style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }} />
        </div>*/}
      </div>

      <section className="bg-primary-alt">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <h1 className="mb-5">
                UX Researcher
                <br />
                Full-time - 100% Remote
              </h1>
              <img
                className="mb-5"
                src="https://www.datocms-assets.com/16499/1583861795-remote-ui-ux-designer-job.png"
              />
              <p className="lead mb-2">
                <strong>Responsibilities:</strong>
              </p>
              <ul className="lead mb-5">
                <li>Identify business and product problems;</li>
                <li>Map current user experience and journeys;</li>
                <li>
                  Design, manage and conduct qualitative and quantitative
                  research-based upon long-term strategic needs as well as
                  ad-hoc research requests
                </li>
                <li>
                  Collect and analyze user behavior through user interviews,
                  ethnography, surveys, benchmark studies, analytics, and
                  experiments, etc.;
                </li>
                <li>
                  Test and validate concepts by conducting usability testing
                  (moderated or unmoderated);
                </li>
                <li>
                  Work with Designers and stakeholders to prioritize research
                  opportunities in startups&apos; context;
                </li>
                <li>
                  Understand and incorporate complex technical and business
                  requirements into research;
                </li>
                <li>
                  Promote the value / ROI of user research in client&apos;s
                  proposals and communications;
                </li>
                <li>
                  Define metrics and calculate research/design ROI during the
                  projects;
                </li>
                <li>
                  Advocate research findings to diverse audiences through
                  written reports and presentations.
                </li>
              </ul>

              <p className="lead mb-2">
                <strong>Requirements:</strong>
              </p>
              <ul className="lead mb-5">
                <li>4+ year of UX research hands-on experience;</li>
                <li>English C1;</li>
                <li>B2B & Complex platforms experience;</li>
                <li>Working during the US business hours;</li>
                <li>
                  Having a good knowledge of the latest UX / UI best practices
                  and trends even though you&apos;re not designing UI directly;
                </li>
                <li>
                  Being a problem-solver. You love finding out you&apos;re
                  wrong;
                </li>
                <li>
                  Consuming large amounts of qualitative and quantitative data
                  to constantly refine your assumptions;
                </li>
                <li>
                  Communicating about any questions/problems/ideas/suggestions,
                  asking for feedback and sharing feedback easily;
                </li>
                <li>
                  Being in charge of tasks, to know and tell others about
                  ability or disability to fit the time limit;
                </li>
                <li>Experience in leading teams is a plus.</li>
              </ul>

              <h2 className="mt-6 mb-3">What we offer</h2>
              
                <ul className="lead mb-5">
                <li className="mb-2">Working remotely (forever);</li>
                <li className="mb-2">No micromanagement;</li>
                <li className="mb-2">Working on complex &amp; challenging products;</li>
                <li className="mb-2">Working directly with a cognitive scientist and UX researcher. The best start for your career;</li>
                <li className="mb-2">Being part of the client facing team from the beginning;</li>
                <li className="mb-2">Talent Development program to grow professionally &ndash; We help you become a better designer.</li>
                </ul>
            </div>

            <div className="col-12 col-md-8">
              <div className="card card--common card-body card--no-hover">
                <div id="activecampaign-form">
                  <div id="activecampaign-form__wrapper">
                    <div className=" _form_21"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    {useScript("https://adamfard48700.activehosted.com/f/embed.php?id=21")}
  </Layout>
);

export default UXresearcherPage;

export const query = graphql`
  query UXresearcherPage {
    datoCmsPage(slug: { eq: "ux-researcher" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;
